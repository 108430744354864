import React from "react";
import BottomSheet from "../../components/BottomSheet";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import useGoogleLogin from "../../hooks/useGoogleLogin";
import { env } from "../../env";
import { getLocalStorage } from "../../utils/function/localStorage";

const SplashScreen = () => {
  const navigate = useNavigate();
  const token = getLocalStorage("tp_Aa_uth_access");

  // Google sign in
  const { responseGoogle, resFailed } = useGoogleLogin();
  return (
    <div className="tpu__splashScreen__wrapper layoutContainer">
      {/* Slider */}
      <div className="tpu__splashScreen__slider">
        <div className="tpu__splashScreen__content">
          <div className="tpu__splashScreen__img">
            <img
              src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014398/tp-app-assests/onboard-1_zeprst.png"
              alt="img"
            />
          </div>
          <div className="tpu__splashScreen__title">
            <h2>A Platform For Online Earners</h2>
          </div>
          <div className="tpu__splashScreen__para">
            <p>
              Task Planet Is An Online Money Earning Website For The Individuals
              Looking For Small Tasks And Getting Paid For It
            </p>
          </div>
        </div>
      </div>
      {/* Bottom Sheet */}
      <BottomSheet height="40%">
        <div className="tpu__splashScreen__bottomSheet__content">
          <div className="section__title">
            <h2>Login with Task Planet</h2>
          </div>
          <div className="section__para">
            <p>
              Login to earn points using various amazing and easy tools provided
              in the app and then use earned points for reward!!
            </p>
          </div>
          <div className="google__login__btn">
            {token ? (
              <button
                type="button"
                className="other__login"
                onClick={() => navigate("/home")}
              >
                Go To Dashboard
              </button>
            ) : (
              <>
                <GoogleLogin
                  clientId={env.google_client_id}
                  buttonText="Sign in with google"
                  onSuccess={responseGoogle}
                  onFailure={resFailed}
                  cookiePolicy={"single_host_origin"}
                  className="google__btn"
                />
                <button
                  type="button"
                  className="other__login"
                  onClick={() => navigate("/authSplashScreen")}
                >
                  Other Login Method
                </button>
              </>
            )}
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default SplashScreen;
