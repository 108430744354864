import React from "react";
import { Route, Routes } from "react-router-dom";
import SplashScreen from "../screens/SplashScreen";
import AuthSplashScreen from "../screens/SplashScreen/AuthSplashScreen";
import MobileLoginScreen from "../screens/AuthPage/MobileLogin";
import EmailLoginScreen from "../screens/AuthPage/EmailLogin";
import MobileForgotPasswordScreen from "../screens/AuthPage/MobileForgotPassword";
import EmailForgotPasswordScreen from "../screens/AuthPage/EmailForgotPassword";
import Dashboard from "./dashboard";
import MobileResetPasswordScreen from "../screens/AuthPage/ResetMobilePassword";
import EmailResetPasswordScreen from "../screens/AuthPage/ResetEmailPassword";
import GoogleAdditionalInfo from "../screens/AuthPage/GoogleAdditionalInfo";
import EmailAdditionalInfo from "../screens/AuthPage/EmailAdditionalInfo";
import MobileAdditionalInfo from "../screens/AuthPage/MobileAdditionalInfo";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/*" index element={<SplashScreen />} />
        <Route path="/home/*" element={<Dashboard />} />
        <Route path="/authSplashScreen" element={<AuthSplashScreen />} />
        <Route path="/loginWithMobile" element={<MobileLoginScreen />} />
        <Route path="/loginWithEmail" element={<EmailLoginScreen />} />
        <Route
          path="/mobileResetPassword"
          element={<MobileResetPasswordScreen />}
        />
        <Route
          path="/mobileForgotPassword"
          element={<MobileForgotPasswordScreen />}
        />
        <Route
          path="/emailForgotPassword"
          element={<EmailForgotPasswordScreen />}
        />
        <Route
          path="/emailResetPassword"
          element={<EmailResetPasswordScreen />}
        />
        <Route
          path="/googleAdditionalInfo"
          element={<GoogleAdditionalInfo />}
        />
        <Route path="/emailAdditionalInfo" element={<EmailAdditionalInfo />} />
        <Route
          path="/mobileAdditionalInfo"
          element={<MobileAdditionalInfo />}
        />
      </Routes>
    </>
  );
};

export default Routers;
