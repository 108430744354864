import React, { useEffect, useRef, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import {
  useCheckOtpMatchMutation,
  useSendOtpMutation,
} from "../../services/userApi";
import { Notification } from "../../components/ToastNotification";

const MobileForgotPasswordScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(40);
  const [otpCode, setOtpCode] = useState("");
  const [data, setData] = useState({
    mobile: "+91",
  });
  const handleChange = (e) => [
    setData({
      ...data,
      [e.target.name]: e.target.value,
    }),
  ];
  // OTP Field ***********************************
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const handleChangeOtp = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
    if (newOtp.every((item) => item !== "")) {
      setOtpCode(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };
  // OTP Field End *****************************

  // Send oTP
  const [
    sendOtp,
    { data: sendOtpData, error: sendOtpError, isLoading: sendOtpIsLoading },
  ] = useSendOtpMutation();

  useEffect(() => {
    if (sendOtpData) {
      setOtpSent(true);
      setCountdown(40);
      Notification(sendOtpData?.message, "success");
    }
    if (sendOtpError) {
      Notification(sendOtpError?.data?.message, "error");
    }
  }, [sendOtpData, sendOtpError]);

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    await sendOtp({ mobile: data?.mobile?.slice(3) });
  };
  // check OTP match
  const [
    checkOtpMatch,
    {
      data: checkOtpMatchData,
      error: checkOtpMatchError,
      isLoading: checkOtpMatchIsLoading,
    },
  ] = useCheckOtpMatchMutation();
  useEffect(() => {
    if (checkOtpMatchData) {
      Notification(checkOtpMatchData?.message, "success");
      navigate("/mobileResetPassword", {
        state: {
          mobile: data?.mobile,
          otp: otpCode,
        },
      });
    }
    if (checkOtpMatchError) {
      Notification(checkOtpMatchError?.data?.message, "error");
    }
  }, [checkOtpMatchData, checkOtpMatchError]);
  const submitOTP = async () => {
    await checkOtpMatch({
      mobile: data?.mobile,
      otp: otpCode,
    });
  };
  // OTP timer
  useEffect(() => {
    if (otpSent) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => countdown > 0 && prevCount - 1);
      }, 1000);

      if (countdown === 0) {
        setOtp(["", "", "", ""]);
      }

      return () => clearInterval(timer);
    }
  }, [otpSent, countdown]);
  return (
    <div className="mobileForgotPassword__wrapper layoutContainer">
      <div className="mobileForgotPassword__header">
        <div className="tpu__header__widgets">
          <span className="leftArrowIcon">
            <FaArrowLeftLong onClick={() => navigate(-1)} />
          </span>
          <div className="tpu__header__name">
            <h2>Forgot Mobile Password</h2>
          </div>
          <span className="questionIcon">
            <GoQuestion />
          </span>
        </div>
      </div>
      <div className="mobileForgotPassword__content__area">
        <div className="mobileForgotPassword__heading">
          <h2>Forgot Password</h2>
          <p>
            Enter the mobile number associated with your account and we'll send
            an otp to reset your password
          </p>
        </div>
        <div className="mobileForgotPassword__image">
          <img
            src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014399/tp-app-assests/signup_urkdhi.jpg"
            alt="img"
          />
        </div>
        {!otpSent ? (
          <div className="mobileForgotPassword__inputField">
            <label htmlFor="mobile">Mobile Number</label>
            <div className="mobileNumberField">
              <span>
                <FaPhoneAlt style={{ color: isFocus ? "#0b59f9" : "#000" }} />
              </span>
              <input
                type="text"
                name="mobile"
                value={data.mobile}
                className="mobileInput"
                onChange={handleChange}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
            </div>
            <div className="submitButton">
              <button
                type="button"
                className="loginButton"
                disabled={!data.mobile?.slice(3) || sendOtpIsLoading}
                onClick={handleSubmitOtp}
              >
                <span>
                  {sendOtpIsLoading ? "Loading..." : "Send Reset OTP"}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className="mobileForgotPassword__inputField">
            <div className="mobileLoginForm">
              <div
                className="mobileNumberField"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "transparent",
                  border: "none",
                }}
              >
                {otp.map((data, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleChangeOtp(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      margin: "5px",
                      fontSize: "22px",
                      outline: "none",
                      background: "#f4f7ff",
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  color: "gray",
                  margin: "10px 0px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  fontFamily: "var(--font-family)",
                  fontSize: "14px",
                }}
              >
                Didn't receive OTP?
                {countdown > 0 && (
                  <p>
                    {" "}
                    Resend in{" "}
                    <strong style={{ fontWeight: "700" }}>
                      {countdown} Seconds
                    </strong>
                  </p>
                )}
              </div>
            </div>

            {countdown > 0 ? (
              <div className="submitButton">
                <button
                  type="button"
                  className="loginButton"
                  disabled={checkOtpMatchIsLoading}
                  onClick={submitOTP}
                >
                  <span>
                    {checkOtpMatchIsLoading ? "Loading..." : "Confirm"}
                  </span>
                </button>
              </div>
            ) : (
              <div className="submitButton">
                <button
                  type="button"
                  className="loginButton"
                  disabled={sendOtpIsLoading}
                  onClick={handleSubmitOtp}
                >
                  <span>{sendOtpIsLoading ? "Loading..." : "Resend OTP"}</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileForgotPasswordScreen;
