import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["User", "Task", "Profile", "leaderBoard", "Wallet"], // automatic-data fetching
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getLoginUser: builder.query({
      query: () => {
        return {
          url: "/common/fetch_login_user",
          method: "GET",
        };
      },
      providesTags: ["User"], // automatic-data fetching
    }),
    getValidateEmail: builder.query({
      query: (email) => {
        return {
          url: `/validate_email?email=${email}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    addGoogleLogin: builder.mutation({
      // user register
      query: (body) => ({
        url: "/google_login",
        method: "POST",
        body: body,
      }),
    }),
    addLoginWithMobile: builder.mutation({
      query: (body) => ({
        url: "/login-with-mobile",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    findMobileNumberOnDatabase: builder.query({
      query: (mobile) => {
        return {
          url: `/find_mobile_no_in_database?mobile=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["Auth"],
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: "/send-otp",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Auth"],
    }),
    checkOtpMatch: builder.mutation({
      query: (body) => ({
        url: "/check-otp-match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    forgetPasswordWithMobile: builder.mutation({
      query: (body) => ({
        url: "/forget_password_with_mobile",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Auth"], // automatic-data fetching
    }),
    sendEmailOtp: builder.mutation({
      query: (body) => {
        return {
          url: "/send_email_otp",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Auth"],
    }),
    checkEmailOtpMatch: builder.mutation({
      query: (body) => ({
        url: "/check-email-otp-match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    addResetPass: builder.mutation({
      query: (body) => ({
        url: `/reset_password`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"], // automatic-data fetching
    }),
    getManageSlider: builder.query({
      query: () => "/secure/get-manage-slider",
      providesTags: ["Task"],
    }),
    myProfile: builder.query({
      query: () => "/secure/profile",
      providesTags: ["Profile"],
    }),
    claimBtn: builder.query({
      query: () => "/secure/claim-button",
      providesTags: ["leaderBoard"],
    }),
    getTotalPoints: builder.query({
      query: () => "/secure/get_total_points",
      providesTags: ["leaderBoard"],
    }),
    getWallet: builder.query({
      query: () => "/secure/get-wallet",
      providesTags: ["Wallet"],
    }),
    dailyLeaderBoard: builder.query({
      query: () => '/secure/daily_leaderboard_live_data',
      providesTags: ['leaderBoard'],
    }),
    weeklyLeaderBoard: builder.query({
      query: () => '/secure/weekly_leaderboard_live_data',
      providesTags: ['leaderBoard'],
    }),
    monthlyLeaderBoard: builder.query({
      query: () => '/secure/monthly_leaderboard_live_data',
      providesTags: ['leaderBoard'],
    }),
    getValidateSponsorId: builder.query({
      query: (sponsorid) => {
        return {
          url: `/sponsorid?username=${sponsorid}`,
          method: "GET",
        };
      },
    }),
    editImage: builder.mutation({
      query: (body) => ({
        url: "/secure/change_profile_pic",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/secure/profile",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    allLeaderBoardHistory: builder.query({
      query: () => '/secure/all_leaderboard_history',
      providesTags: ['leaderBoard'],
    }),
    myTotalReferrals: builder.query({
      query: ({username}) => `/secure/total-referrals/${username}`,
      providesTags: ['Team'],
    }),
    selfEarning: builder.query({
      query: ({page, limit}) =>
        `/secure/get-self-earning-history?page=${page}&limit=${limit}`,
      providesTags: ['Income'],
    }),
    referralEarning: builder.query({
      query: ({page, limit}) =>
        `/secure/get-referral-earning-history?page=${page}&limit=${limit}`,
      providesTags: ['Income'],
    }),
    rewardIncome: builder.query({
      query: ({page, limit}) =>
        `/secure/fetch_Reward_income?page=${page}&limit=${limit}`,
      providesTags: ['Income'],
    }),
    changeEmail: builder.mutation({
      query: (body) => ({
        url: "/secure/change_email",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),

    // OTP
    sendOtpSecure: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/send-OPT",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    verifyMobile: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/verify-mobile",
          method: "PUT",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    setDOB: builder.mutation({
      query: (body) => ({
        url: "/secure/set-dob",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getValidateUsername: builder.query({
      query: (username) => {
        return {
          url: `/validate_username?username=${username}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    getValidateMobile: builder.query({
      query: (mobile) => {
        return {
          url: `/validate_mobile?mobile=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    addMobileRegister: builder.mutation({
      query: (body) => ({
        url: "/mobile_register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getLeaderBoardUserInfo: builder.query({
      query: username =>
        `/secure/get-leader-board-user-info?username=${username}`,
      providesTags: ['leaderBoard'],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetValidateEmailQuery,
  useAddGoogleLoginMutation,
  useGetLoginUserQuery,
  useAddLoginWithMobileMutation,
  useFindMobileNumberOnDatabaseQuery,
  useSendOtpMutation,
  useCheckOtpMatchMutation,
  useForgetPasswordWithMobileMutation,
  useSendEmailOtpMutation,
  useCheckEmailOtpMatchMutation,
  useAddResetPassMutation,
  useGetManageSliderQuery,
  useMyProfileQuery,
  useClaimBtnQuery,
  useGetTotalPointsQuery,
  useGetWalletQuery,
  useGetValidateSponsorIdQuery,
  useEditImageMutation,
  useUpdateProfileMutation,
  useChangeEmailMutation,
  useSendOtpSecureMutation,
  useVerifyMobileMutation,
  useSetDOBMutation,
  useRegisterMutation,
  useGetValidateUsernameQuery,
  useGetValidateMobileQuery,
  useAddMobileRegisterMutation,
  useAllLeaderBoardHistoryQuery,
  useGetLeaderBoardUserInfoQuery,
  useDailyLeaderBoardQuery,
  useWeeklyLeaderBoardQuery,
  useMonthlyLeaderBoardQuery,
  useMyTotalReferralsQuery,
  useSelfEarningQuery,
  useReferralEarningQuery,
  useRewardIncomeQuery
} = userApi;
