/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { getLocalStorage } from "../../utils/function/localStorage";
import {
  useGetValidateEmailQuery,
  useGetValidateMobileQuery,
  useGetValidateSponsorIdQuery,
  useGetValidateUsernameQuery,
  useRegisterMutation,
} from "../../services/userApi";
import { Validate } from "../../components/validation";
import { Notification } from "../../components/ToastNotification";
import { useNavigate } from "react-router-dom";

const EmailAdditionalInfo = () => {
  const navigate = useNavigate();

  const [sponsorName, setSponsorName] = useState("");
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // error are here
  const [uerror, setUerror] = useState("");
  const [eerror, setEerror] = useState("");
  const [merror, setMerror] = useState("");
  const [sponError, setSponError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isFocus, setIsFocus] = useState(false);

  // user data are here
  const [user, setUser] = useState({
    sponsor_id: "",
    sponsor_name: "",
    first_name: "",
    last_name: "",
    username: "",
    mobile: "",
    email: "",
    password: "",
    cpassword: "",
    otp: "",
  });

  const handleChange = (name, value) => {
    setUser({
      ...user,
      [name]: value,
    });
  };

  const [register, { data, isLoading, error }] = useRegisterMutation();
  const { data: sponsoridData, error: sponsoridError } =
    useGetValidateSponsorIdQuery(user.sponsor_id?.toLowerCase());
  const { data: usernameData, error: usernameError } =
    useGetValidateUsernameQuery(user.username?.toLowerCase());
  // email validate
  const { data: emailData, error: emailError } = useGetValidateEmailQuery(
    user.email
  );
  // mobile validate
  const { data: mobileData, error: mobileError } = useGetValidateMobileQuery(
    user.mobile.slice(1)
  );

  //  signup dispatch
  const handleSignup = async () => {
    setFormErrors(Validate(user));
    const newObj = {
      username: user.username.toLowerCase(),
      email: user?.email,
      mobile: user.mobile,
      password: user.password,
      cpassword: user.cpassword,
      first_name: user.first_name,
      last_name: user.last_name,
      sponsorid: user.sponsor_id.toLowerCase(),
      sponsorname: sponsorName,
      otp: user?.otp,
      androidApp: true,
    };
    if (Object.keys(formErrors).length > 0) {
      Notification("All conditions and fields are required", "error");
    } else if (checked === false) {
      Notification("Kindly click on I agree to terms and conditions", "error");
    } else {
      await register(newObj);
    }
  };

  useEffect(() => {
    if (error) {
      Notification(error?.data?.message || "Something went wrong", "error");
    }
    if (data) {
      Notification(data?.message, "success");
      navigate("/home");
    }
  }, [data, error]);

  useEffect(() => {
    if (sponsoridData?.message) {
      setSponError(sponsoridData?.message);
      setSponsorName(
        sponsoridData?.data?.first_name?.charAt(0).toUpperCase() +
          sponsoridData?.data?.first_name?.slice(1) +
          " " +
          sponsoridData?.data?.last_name?.charAt(0).toUpperCase() +
          sponsoridData?.data?.last_name?.slice(1)
      );
    }
    if (sponsoridError?.data?.message) {
      setSponError(sponsoridError?.data?.message);
      setSponsorName("");
    }
  }, [sponsoridError?.data?.message, sponsoridData]);

  useEffect(() => {
    if (usernameData?.message) {
      setUerror(usernameData?.message);
    }
    if (usernameError?.data?.message) {
      setUerror(usernameError?.data?.message);
    }
  }, [usernameError?.data?.message, usernameData?.message]);

  useEffect(() => {
    setTimeout(() => {
      if (emailData?.message) {
        setEerror(emailData?.message);
      }
      if (emailError?.data?.message) {
        setEerror(emailError?.data?.message);
      }
    }, 3000);
  }, [emailError?.data?.message, emailData?.message]);

  useEffect(() => {
    if (mobileData?.message) {
      setMerror(mobileData?.message);
    }
    if (mobileError?.data?.message) {
      setMerror(mobileError?.data?.message);
    }
  }, [mobileError, mobileData]);

  // handle form error
  useEffect(() => {
    setFormErrors(Validate(user));
  }, [
    user.sponsor_id,
    user.sponsor_name,
    user.first_name,
    user.last_name,
    user.username,
    user.mobile,
    user.email,
    user.password,
    user.cpassword,
  ]);

  useEffect(() => {
    const res = getLocalStorage("emailAdditionalInfo");
    if (res != null) {
      const emailAdditionalInfo = JSON.parse(res);
      setUser({
        ...user,
        email: emailAdditionalInfo?.email,
        otp: emailAdditionalInfo?.otp,
      });
    }
  }, []);

  return (
    <div style={{ marginTop: "40px" }} className="layoutContainer">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://res.cloudinary.com/green-valley-grocery/image/upload/v1667536148/tp-app-assests/avatar_hlnwhp.png"
            }
            style={{ width: "70px", height: "70px", borderRadius: "70px" }}
            alt=""
          />
        </div>
        <p
          style={{
            color: "#575757",
            fontWeight: "bold",
            textAlign: "center",
            paddingTop: "15px",
            fontSize: "16px",
          }}
        >
          You are all set to earn
        </p>
        <p
          style={{
            color: "gray",
            textAlign: "center",
            paddingTop: "5px",
          }}
        >
          Start earning by completing tasks
        </p>
      </div>

      <div style={{ paddingTop: "20px" }}>
        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Enter sponsor id (Optional)"
            placeholderTextColor="gray"
            name="sponsorid"
            onChange={(e) => handleChange("sponsor_id", e.target.value)}
            // value={user?.sponsorid}
          />
          {user?.sponsor_id &&
            !formErrors.sponsor_id &&
            !sponError.includes("Not Found") && (
              <p
                style={{
                  color: !sponError?.includes("Invalid") ? "green" : "red",
                  marginLeft: "10px",
                  fontSize: "12px",
                }}
              >
                {sponError}
              </p>
            )}
        </div>
        {sponsorName && (
          <div style={{ padding: "0 10px 15px" }}>
            <input
              style={{
                height: "40px",
                width: "100%",
                border: "none",
                borderBottom: "1px solid #aaaaaa",
                padding: "10px",
                backgroundColor: "#f5f5f5",
                color: "#000",
              }}
              placeholder="Sponsor Name"
              placeholderTextColor="gray"
              name="sponsor_name"
              // onChange={(e) => handleChange("username", e.target.value)}
              value={sponsorName}
            />
          </div>
        )}
        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Username"
            placeholderTextColor="gray"
            name="username"
            onChange={(e) => handleChange("username", e.target.value)}
            value={user?.username}
          />
          {formErrors.username &&
            !formErrors.username?.includes("required") && (
              <p
                style={{
                  color: "#ff3333",
                  marginLeft: "10px",
                  fontSize: "12px",
                }}
              >
                {formErrors.username}
              </p>
            )}
          {user.username.length > 2 && !formErrors.username && uerror && (
            <p
              style={{
                color: uerror === "Username taken!" ? "red" : "green",
                marginLeft: "10px",
                fontSize: "12px",
              }}
            >
              {uerror}
            </p>
          )}
        </div>
        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="First Name"
            placeholderTextColor="gray"
            name="first_name"
            onChange={(e) => handleChange("first_name", e.target.value)}
            value={user?.first_name}
          />
          {formErrors.first_name && (
            <p style={{ color: "#ff3333", marginLeft: 10, fontSize: 12 }}>
              {formErrors.first_name}
            </p>
          )}
        </div>

        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Last Name"
            placeholderTextColor="gray"
            name="last_name"
            onChange={(e) => handleChange("last_name", e.target.value)}
            value={user?.last_name}
          />
          {formErrors.last_name && (
            <p
              style={{
                color: "#ff3333",
                marginLeft: "10px",
                fontSize: "12px",
              }}
            >
              {" "}
              {formErrors.last_name}
            </p>
          )}
        </div>
        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Email"
            placeholderTextColor="gray"
            name="email"
            // onChange={(e) => handleChange("email", e.target.value)}
            value={user?.email}
          />
          {formErrors.email && !formErrors.email?.includes("required") && (
            <p
              style={{
                color: "#ff3333",
                marginLeft: "10px",
                fontSize: "12px",
              }}
            >
              {formErrors.email}
            </p>
          )}
          {user?.email?.length >= 3 && !formErrors.email && eerror && (
            <p
              style={{
                color: eerror === "Email available!" ? "green" : "red",
                marginLeft: "10px",
                fontSize: "12px",
              }}
            >
              {eerror}
            </p>
          )}
        </div>
        <div style={{ padding: "0 10px 15px" }}>
          <div className="mobileNumberField">
            <span>
              <FaPhoneAlt style={{ color: isFocus ? "#0b59f9" : "#000" }} />
            </span>
            <input
              type="text"
              name="mobile"
              // value={user?.mobile}
              defaultValue={"+91"}
              className="mobileInput"
              onChange={(e) => handleChange("mobile", e.target.value)}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            />
          </div>

          {!(user?.mobile.length === 10 || user?.mobile.length === 13) ? (
            <p
              style={{
                color: "#ff3333",
                marginLeft: "10px",
                fontSize: "12px",
              }}
            >
              Invalid Mobile
            </p>
          ) : (
            <>
              {formErrors.mobile && !merror && (
                <p
                  style={{
                    color: "#ff3333",
                    marginLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {formErrors.mobile}
                </p>
              )}
              {merror && (
                <p
                  style={{
                    color: merror === "Mobile taken!" ? "red" : "green",
                    marginLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {merror}
                </p>
              )}
            </>
          )}
        </div>
        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Password"
            placeholderTextColor="gray"
            name="password"
            onChange={(e) => handleChange("password", e.target.value)}
            value={user?.password}
            type={showPassword ? "text" : "password"}
          />
        </div>
        <div style={{ padding: "0 10px 15px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Confirm Password"
            placeholderTextColor="gray"
            name="cpassword"
            onChange={(e) => handleChange("cpassword", e.target.value)}
            value={user?.cpassword}
            type={showPassword ? "text" : "password"}
          />
        </div>

        <div
          style={{
            padding: "0 10px 15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            name=""
            style={{ width: "16px", height: "16px" }}
            value={showPassword}
            onChange={(e) => setShowPassword(e.target.checked)}
          />
          <span style={{ marginLeft: "10px", color: "#575757" }}>
            Show Password
          </span>
        </div>

        <div
          style={{
            padding: "0 10px 15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            name=""
            style={{ width: "16px", height: "16px" }}
            value={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <span style={{ marginLeft: "10px", color: "#575757" }}>
            I agree to{" "}
            <a
              href="https://www.taskplanet.org/termsconditions"
              target="_bland"
            >
              terms and condition
            </a>
          </span>
        </div>

        <div style={{ padding: "0 10px 15px", marginTop: 30 }}>
          <button
            disabled={
              isLoading ||
              Object.keys(formErrors).length > 0 ||
              user.mobile?.length !== 13 ||
              (user?.sponsor_id?.length > 0 &&
                sponError?.includes("Invalid")) ||
              uerror === "Username taken!"
            }
            style={{
              backgroundColor: "#3871ff",
              padding: "10px",
              borderRadius: "5px",
              width: "100%",
              border: "none",
            }}
            onClick={() => handleSignup()}
          >
            <p
              style={{
                color: "#fff",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {isLoading ? "Loading..." : "SIGNUP"}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailAdditionalInfo;
