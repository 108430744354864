import React, { useEffect, useState } from "react";
import { FaArrowLeftLong, FaLock } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddResetPassMutation } from "../../services/userApi";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { Notification } from "../../components/ToastNotification";

const EmailResetPasswordScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const [isFocus2, setFocus2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [addResetPass, {error, data, isLoading}] = useAddResetPassMutation();

  const [user, setUser] = useState({
    email: location.state?.email,
    otp: location.state?.otp,
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };


  useEffect(() => {
    if (data) {

      Notification(data?.message, "success");
      navigate("/authSplashScreen");
    }
    if (error) {
      Notification(error?.data?.message, "error");
    }
  }, [data, error, navigate]);

  const handleResetPassword = async () => {
    const newObj = {
      email: user?.email,
      otp: user?.otp,
      password: user?.password,
    };
    if (user?.password !== user?.confirmPassword) {
      Notification("Password Does not match", "error");
    } else {
      if (user?.email && user?.otp && user?.password) {
        await addResetPass(newObj);
      }
    }
  };

  return (
    <div className="mobileResetPassword__wrapper layoutContainer">
      <div className="mobileForgotPassword__header">
        <div className="tpu__header__widgets">
          <span className="leftArrowIcon">
            <FaArrowLeftLong onClick={() => navigate(-1)} />
          </span>
          <div className="tpu__header__name">
            <h2>Reset Email Password</h2>
          </div>
          <span></span>
        </div>
      </div>
      <div className="mobileResetPassword__content__area">
        <div className="mobileForgotPassword__inputField">
          <div className="mobileForgotPassword__group">
            <label htmlFor="mobile">New Password</label>
            <div className="mobileNumberField" style={{ border: isFocus ? "1px solid #0b59f9" : "1px solid #ececf2" }}>
              <span>
                <FaLock style={{ color: isFocus ? "#0b59f9" : "#000" }} />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={user.password}
                className="passwordInput"
                onChange={handleChange}
                placeholder="Password"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
              <span className="eyeIcon">
                {showPassword ? (
                  <IoEye onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <IoMdEyeOff onClick={() => setShowPassword(!showPassword)} />
                )}
              </span>
            </div>
          </div>
          <div className="mobileForgotPassword__group">
            <label htmlFor="mobile">Confirm Password</label>
            <div className="mobileNumberField" style={{ border: isFocus2 ? "1px solid #0b59f9" : "1px solid #ececf2" }}>
              <span>
                <FaLock style={{ color: isFocus2 ? "#0b59f9" : "#000" }} />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={user.confirmPassword}
                className="passwordInput"
                onChange={handleChange}
                placeholder="Confirm Password"
                onFocus={() => setFocus2(true)}
                onBlur={() => setFocus2(false)}
              />
              <span className="eyeIcon">
                {showPassword ? (
                  <IoEye onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <IoMdEyeOff onClick={() => setShowPassword(!showPassword)} />
                )}
              </span>
            </div>
          </div>
          <div className="submitButton">
            <button
              type="button"
              className="loginButton"
              disabled={isLoading}
              onClick={handleResetPassword}
            >
              <span>{isLoading ? "Loading..." : "Reset Password"}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailResetPasswordScreen;
