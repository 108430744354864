import React, { useEffect, useState } from "react";
import {
  getLocalStorage,
  savedLocalStorage,
} from "../../utils/function/localStorage";
import { Notification } from "../../components/ToastNotification";
import { useNavigate } from "react-router-dom";
import { useAddMobileRegisterMutation } from "../../services/userApi";

const MobileAdditionalInfo = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    sponsorid: "",
    first_name: "",
    last_name: "",
    mobile: "",
  });

  const [
    addMobileRegister,
    { data: mData, error: mError, isLoading: mIsLoading, isError: mIsError },
  ] = useAddMobileRegisterMutation();

  const handleChange = (name, value) => {
    setUser({
      ...user,
      [name]: value,
    });
  };

  // Function to retrieve referrerUrl from AsyncStorage
  const getReferrerUrl = async () => {
    try {
      const url = await getLocalStorage("referrerUrl");
      if (url !== null) {
        handleChange("sponsorid", url);
        console.log("Retrieved referrer URL from AsyncStorage:", url);
      } else {
        console.log("No referrer URL found in AsyncStorage");
      }
    } catch (error) {
      console.log("Error retrieving referrer URL from AsyncStorage:", error);
    }
  };

  // Use useEffect to retrieve referrerUrl when component mounts
  useEffect(() => {
    getReferrerUrl();
  }, []);

  const handleMobileLogin = async () => {
    const data = {
      sponsorid: user?.sponsorid?.toLowerCase() || "taskplanet",
      first_name: user.first_name,
      last_name: user.last_name,
      mobile: user.mobile,
      androidApp: true,
    };
    console.log(data);
    if (user?.first_name && user?.last_name && user?.mobile) {
      await addMobileRegister(data);
    }
  };

  useEffect(() => {
    const res = getLocalStorage("loginUserInfo");
    if (res != null) {
      const mobileUser = JSON.parse(res);
      setUser((prevState) => ({
        ...prevState,
        mobile: mobileUser?.mobile,
      }));
    }
  }, []);

  useEffect(() => {
    if (mData?.message) {
      Notification(mData?.message, "success");
      navigate("/home");
      savedLocalStorage("userToken", mData);
    }
    if (mIsError) {
      Notification(mError?.data?.message || "Something went wrong", "success");
    }
  }, [mData, mError, mIsError]);

  return (
    <div className="layoutContainer">
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          padding: "40px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://res.cloudinary.com/green-valley-grocery/image/upload/v1667536148/tp-app-assests/avatar_hlnwhp.png"
            }
            style={{ width: "70px", height: "70px", borderRadius: "70px" }}
            alt=""
          />
        </div>
        <p
          style={{
            color: "#575757",
            fontWeight: "700",
            textAlign: "center",
            paddingTop: "15px",
            fontSize: "16px",
          }}
        >
          You are all set to earn
        </p>
        <p
          style={{
            color: "gray",
            textAlign: "center",
            paddingTop: "5px",
          }}
        >
          Start earning by completing tasks
        </p>

        <div style={{ paddingTop: "20px" }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Enter sponsor id (Optional)"
            placeholderTextColor="gray"
            name="sponsorid"
            onChange={(e) =>
              handleChange("sponsorid", e.target.value?.toLowerCase())
            }
            // value={sponsorId}
            defaultValue="taskplanet"
          />
        </div>

        <div style={{ paddingTop: 20 }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="First Name"
            placeholderTextColor="gray"
            name="first_name"
            onChange={(e) => handleChange("first_name", e.target.value)}
            value={user?.first_name}
          />
        </div>
        <div style={{ paddingTop: 20 }}>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              borderBottom: "1px solid #aaaaaa",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              color: "#000",
            }}
            placeholder="Last Name"
            placeholderTextColor="gray"
            name="last_name"
            onChange={(e) => handleChange("last_name", e.target.value)}
            value={user?.last_name}
          />
        </div>

        <div style={{ marginTop: 30 }}>
          <button
            // disabled={gLoading}
            style={{
              backgroundColor: "#3871ff",
              padding: "10px",
              borderRadius: "5px",
              width: "100%",
              border: "none",
            }}
            onClick={() => handleMobileLogin()}
          >
            <p
              style={{
                color: "#fff",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {mIsLoading ? "Loading..." : "PROCEED"}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileAdditionalInfo;
