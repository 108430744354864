import React, { useEffect } from "react";
import Helmet from "react-helmet";
import logo from "../assets/images/logo.png";
import { Toaster } from "react-hot-toast";
import Routers from "../routes";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../utils/function/localStorage";
const App = () => {
  // const navigate = useNavigate();
  // const token = getLocalStorage("tp_Aa_uth_access");
  // useEffect(() => {
  //   if (token) {
  //     navigate("/home");
  //   }
  // }, [navigate, token]);
  return (
    <>
      <Helmet>
        <title>Taskplanet</title>
        <link rel="shortcut icon" href={logo} type="image/x-icon" />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <Toaster position="top-center" reverseOrder={true} />
      <Routers />
    </>
  );
};

export default App;
